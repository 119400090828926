.about-us-section {
  background-color: black;
  padding: 50px 0;
}

.about-us-heading {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.about-us-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  color: white;
  line-height: 1.8;
  margin-bottom: 20px;
}

.about-us-text-container {
  max-width: 600px;
}

.about-us-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Enlarged image for better visibility */
.about-us-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Media query for larger screens (e.g., desktops) */
@media (min-width: 1024px) {
  .about-us-image {
    max-width: 700px; /* Increase max-width on larger screens */
  }
}

/* Thumbnail image adjustments */
.about-us-thumbnail {
  max-width: 100px; /* Shrink thumbnails */
  height: auto;
  transition: transform 0.2s ease; /* Smooth transition for hover effect */
}

/* On hover, zoom in a bit */
.about-us-thumbnail:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}
