/* Basic styling for the contact section */
.contact-us-section {
    background-color: #f9f9f9;
    padding: 50px 0;
    text-align: center;
  }
  
  .section-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .section-subtitle {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #555;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Form styling */
  .contact-form {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-input,
  .form-textarea {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
    color: #333;
    background-color: white;
    transition: all 0.3s ease;
  }
  
  .form-input:focus,
  .form-textarea:focus {
    border-color: #555;
    outline: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-textarea {
    height: 150px;
    resize: none;
  }
  
  /* Submit button styling */
  .submit-button {
    background-color: #000;
    color: #fff;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #444;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .section-title {
      font-size: 2rem;
    }
  
    .section-subtitle {
      font-size: 1rem;
    }
  }
  