.sales-package-section {
    background-color: #f8f9fa;
    padding: 60px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title {
    margin-bottom: 40px;
    text-align: center;
}

.borders-before span {
    display: inline-block;
    padding: 0 20px;
    background-color: #fff;
    position: relative;
    z-index: 1;
}

.borders-before:before, .borders-before:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 50px;
    height: 2px;
    background-color: #ddd;
    z-index: 0;
}

.borders-before:before {
    left: 0;
}

.borders-before:after {
    right: 0;
}

.main-title {
    font-size: 2.5rem;
    color: #333;
}

.sub-title {
    color: #777;
    margin-top: 10px;
    font-size: 1.1rem;
}

.pricing-box {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative; /* Ensure proper stacking */
    z-index: 1; /* Bring the box and buttons to the front */
  }
  
.pricing-box:hover {
    transform: translateY(-10px);
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
}

.package-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: 700;
}

.package-price {
    font-size: 2rem;
    color: black;
    margin-bottom: 20px;
    font-weight: 700;
}

.package-features {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;
}

.package-features li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
}

.btn-primary {
    background: linear-gradient(90deg, black, grey);
    border: none;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 25px;
    transition: background 0.3s ease;
}

.btn-primary:hover {
    background: linear-gradient(90deg, grey, white);
    color: #333;
}

/* Responsive adjustments */
@media (min-width: 768px) {
    .pricing-box {
        margin: 0 15px; /* Add some margin between cards on larger screens */
    }
}

/* Responsive adjustments for buttons */
@media (max-width: 767px) {
    .btn-primary {
      width: 100%; /* Ensure the button stretches full width on mobile */
      font-size: 1rem;
      padding: 12px;
    }
  }
  


