/* Container for each trainer card */
.trainer-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  /* Hover effect for the cards */
  .trainer-card:hover {
    transform: translateY(-10px);
  }
  
  /* Adjust the slider container */
  .trainer-slider-section .slick-list {
    padding: 0 15px; /* Add padding to the slider container */
  }
  
  .slick-slide {
    padding: 0 10px; /* Add space between slides */
  }
  
  /* Trainer image styling */
  .trainer-image img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  /* Trainer name styling */
  .trainer-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  /* Trainer review styling */
  .trainer-review {
    font-size: 1rem;
    color: #555;
    font-style: italic;
  }
  .trainer-font-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -100px;
    margin-bottom:-100px;
  }



  /* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    .trainer-card {
      padding: 15px;
    }
  
    .slick-slide {
      padding: 0 5px; /* Adjust space for smaller screens */
    }
  
    .trainer-name {
      font-size: 1.25rem;
    }
  
    .trainer-review {
      font-size: 0.9rem;
    }
  }
  
  body{
    background-color: black;
  }
  /* For the fade-in/out animation */
.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}