.footer {
  background-color: #000;
  color: #fff;
  padding: 40px 0;
  text-align: center;
  position: relative;
}

.footer .footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer .footer-container .social-media {
  margin-bottom: 20px;
}

.footer .social-media a {
  margin: 0 10px;
  color: #fff;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.footer .social-media a:hover {
  color: white;
}

.footer .footer-contact {
  margin-bottom: 20px;
}

.footer .footer-contact a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer .footer-contact a:hover {
  text-decoration: underline; /* Underline when hovered */
  color: #fff;
}

.footer .footer-contact p {
  margin: 0;
}

.footer .footer-copyright {
  border-top: 1px solid #555;
  padding-top: 10px;
  margin-top: 20px;
  font-size: 0.9rem;
  color: #777;
}
.footer .social-media a {
  margin: 0 10px;
  color: #fff;
  font-size: 1.5rem;
  transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition for both transform and color */
}

.footer .social-media a:hover {
  transform: scale(1.2); /* Enlarge the icon by 20% */
  color: #fff; /* Keep the color the same but you can change it if you want */
}
/* Hover effect for Instagram icon */
.footer .social-media a[href*="instagram"] {
  color: #fff;
}

.footer .social-media a[href*="instagram"]:hover {
  color: #E1306C; /* Instagram red/pink color */
}

/* Hover effect for Facebook icon */
.footer .social-media a[href*="facebook"] {
  color: #fff;
}

.footer .social-media a[href*="facebook"]:hover {
  color: #1877F2; /* Facebook blue color */
}

/* Hover scale effect for all social media icons */
.footer .social-media a:hover {
  transform: scale(1.2); /* Enlarge the icon by 20% */
}